import React from "react"
import Layout from "../../components/layout"

export default function Contact() {
  return (
    <Layout>
      <>
      <section className="flex justify-content-center-space-between align-items-center halves m-t-3">
        <section className="bordered">
          <h1>Contact Us!</h1>
          <form className="m-y-2" name="contact" method="POST" action="/contact/thanks/" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label htmlFor="name">Name</label>
              <input required type="text" name="name" id="name" />
            </p>
            <p>
              <label htmlFor="email">Email</label>
              <input required type="text" name="email" id="email" />
            </p>
            <p>
              <label htmlFor="email">Message</label>
              <textarea rows={4} name="message" id="message"></textarea>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </section>

        <div><img src="/assets/images/contact-2.jpg"></img></div>
      </section>

      <section className="flex justify-content-center-space-between align-items-center m-t-3">
        <section className="bordered">
          <h1>Book a Call!</h1>

          <p>Have questions about our social media marketing and blogging services? Want to learn more about how we can help? Book a free <a target="_blank" href="https://calendly.com/alexandra-beane-well-from-within/30min">30-minute intro call</a>! We look forward to hearing about your nutrition, health coaching, naturopathic, or functional medicine practice.</p>
          <p>Alternatively, if you're ready to request a sample article for your blog, simply use our contact form to submit your request.</p>

          <div className="calendly-inline-widget m-t-2" data-url="https://calendly.com/alexandra-beane-well-from-within/30min" style={{minWidth: '320px', height: '700px'}}></div>
          <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
        </section>

      </section>
    </>
    </Layout>

  )
}